import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Image } from '../../components/library'

function image() {
    return (
        <LibraryWrapper>
            <Image src={'/batteries.png'} width="70%" padding background="white" borderRadius={20} shadow />
            <hr />
            <Image src={'/watch.png'} aspectRatio={0.5} width={320} />
            <hr />
            <Image src={'/1000x500.png'} aspectRatio={1.75} />
        </LibraryWrapper>
    )
}
export default image
